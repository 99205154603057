<template>
	<div id="app">
		<div v-if="$route.path === '/tulikas/fireaudit' || $route.path === '/tulikas/fireaudit/check'"
			 style="margin-left: 1em;margin-right: 1em;"
		>
			<router-view/>
		</div>

		<div v-else>
			<div id="nav" v-if="isLogged">
				<router-link to="/tulikas/office-fireaudit">Kontori tuleaudit</router-link> |
				<router-link to="/settings">Seaded-test</router-link> |
				<router-link to="/imports">Import</router-link> |
				<router-link to="/categories">Kategooriad</router-link> |
				<router-link to="/transactions">Sissekanded</router-link> |
				<router-link to="/rules">Reeglid</router-link> |
				[{{ $store.state.user.name }}]
				<b-link @click="logout()">Logi välja</b-link>
			</div>

			<b-row class="m-2">
				<b-col class="m-2">
					<router-view/>
				</b-col>
			</b-row>
		</div>

	</div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
    	data() {
    		return {
				menu: [
					{
						header: true,
						title: 'Main Navigation',
						hiddenOnCollapse: true
					},
					{
						href: '/',
						title: 'Dashboard',
						icon: 'fa fa-user'
					},
					{
						href: '/charts',
						title: 'Charts',
						icon: 'fa fa-chart-area',
						child: [
							{
								href: '/charts/sublink',
								title: 'Sub Link'
							}
						]
					}
				]
			}
		},
        computed: {
            ...mapGetters([
                'isLogged'
            ])
        },
        methods: {
            logout () {
                this.$store.dispatch('logout');
            }
        }
    }
</script>

<style lang="scss">
	html, body {
		height: 100vh;
		background: url(https://tulikas.ee/wp-content/uploads/2022/02/iStock-179013150-2048x1365.jpg) rgba(239, 123, 50, 0.85);
		opacity: 0.9;
		background-size: cover;
		background-repeat: repeat;
		background-blend-mode: multiply;
	}
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #ffffff;
		height: 100vh;
		background: url(https://tulikas.ee/wp-content/uploads/2022/02/iStock-179013150-2048x1365.jpg) rgba(239, 123, 50, 0.85);
		opacity: 1;
		background-size: cover;
		background-repeat: repeat;
		background-blend-mode: multiply;
    }
    #nav {
        padding: 30px;
        a {
            font-weight: bold;
            color: #2c3e50;
            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
