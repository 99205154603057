<template>
	<b-container>
		<img alt="Vanamaja logo" src="../assets/vanamaja-logo.png">
		<hr>
		<b-button to="/tulikas/fireaudit/check"
				  variant="warning"
				  class="mt-4 mb-4"
		>
			Kontrolli, kas sinu hoone on tuleohutusülevaatuse kohuslane
		</b-button>
	</b-container>
</template>

<script>

	export default {
		name: 'Avaleht',
		methods: {

		}
	};
</script>

<style>

</style>
