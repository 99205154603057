import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import vSelect from 'vue-select';

// broadcasting
window.pusher = require('pusher-js');

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/src/scss/vue-select.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('v-select', vSelect);

import './scss/main.scss';

Vue.config.productionTip = false;

import {eventBus} from '@/eventbus';

new Vue({
    router,
    store,
	eventBus,
    created() {
        const userInfo = localStorage.getItem('user');

        if (userInfo) {
            const user = JSON.parse(userInfo);
            this.$store.commit('setUser', user);

			axios.get('/team_data').then(({data}) => {
				this.$store.commit('setTeamData', data);
			})
        }

        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    this.$store.dispatch('logout');
                }
                return Promise.reject(error);
            }
        );

    },
    render: h => h(App),
}).$mount('#app');
